.map-container {
  position: relative;
  width: 100%;
  height: 600px; /* גובה המסלול */
}

.square {
  position: absolute;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow {
  position: absolute;
  width: 40px;
  height: 40px;
}

/* מיקום ריבועים */
.square1 {
  top: 500px;
  left: 50px;
}
.square2 {
  top: 350px;
  left: 150px;
}
.square3 {
  top: 200px;
  left: 250px;
}
.square4 {
  top: 350px;
  left: 350px;
}
.square5 {
  top: 500px;
  left: 450px;
}

/* מיקום חצים */
.arrow1 {
  top: 450px;
  left: 90px;
}
.arrow2 {
  top: 300px;
  left: 200px;
}
.arrow3 {
  top: 300px;
  left: 300px;
}
.arrow4 {
  top: 450px;
  left: 400px;
}







