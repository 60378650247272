@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500&display=swap');


/* @tailwind base;
@tailwind components;
@tailwind utilities; */
 
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* html{
  height: -webkit-fill-available;
} */
.root{
  height: 100%
}
body {
  height: 100svh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: -webkit-fill-available; */
  overscroll-behavior: none
}
/* html,
body {
 padding: 0;
 margin: 0;
 overflow: hidden;
 width: 100vw;
 height: 100vh;
 height: var( — 100%);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
