.notification-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .notification-icon {
    font-size: 24px;
    position: relative;
  }
  
  .icon {
    font-size: 30px; /* גודל האייקון */
  }
  
  .badge {
    position: absolute;
    top: 1px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: bold;
    min-width: 20px;
    text-align: center;
  }